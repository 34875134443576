<template>
  <div class="nav-section">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.nav-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 36px;
  padding-inline: 12px;
  font-size: 14px;
  color: var(--gray-3);
}
</style>
