<template>
  <div class="NavButtonGroup">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.NavButtonGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
