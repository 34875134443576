<template>
  <div class="nav-separator">
    <div class="nav-separator__hr"></div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.nav-separator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 32px;
  padding-inline: 12px;
  flex-shrink: 0;

  &__hr {
    height: 1px;
    background: var(--gray-6);
  }
}
</style>
